import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import franceFlag from '../../../assets/france.svg';
import ukFlag from '../../../assets/uk.svg';
import spainFlag from '../../../assets/spain.svg';
import './LangueSwitcher.css';
import { useNavigate } from 'react-router-dom';

const LanguageSwitcher = () => {
  
  const { i18n } = useTranslation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();

  const currentLanguage = i18n.language;

  const changeLanguage = (lng) => {
    const currentPath = window.location.pathname;
    const currentSearch = window.location.search; 
    const newPath = currentPath.replace(`/${currentLanguage}`, `/${lng}`);
  
    i18n.changeLanguage(lng)
      .then(() => {
        setIsDropdownOpen(false);
        navigate(`${newPath}${currentSearch}`); 
      })
      .catch((err) => {
        console.error("Changement de langue échoué :", err);
      });
  };

  const languages = {
    fr: { flag: franceFlag, name: 'FR' },
    en: { flag: ukFlag, name: 'EN' },
    es: { flag: spainFlag, name: 'ES' }
  };

  return (
    <div className="language-switcher">
      <button 
        className="language-btn" 
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        <img src={languages[currentLanguage]?.flag} alt={currentLanguage} /> {languages[currentLanguage]?.name}
      </button>
      {isDropdownOpen && (
        <ul className="language-dropdown">
          {Object.entries(languages).map(([key, { flag, name }]) => (
            key !== currentLanguage && (
              <li key={key} onClick={() => changeLanguage(key)}>
                <img src={flag} alt={key} /> {name}
              </li>
            )
          ))}
        </ul>
      )}
    </div>
  );
};

export default LanguageSwitcher;
