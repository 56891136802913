import React, { useState } from 'react';
import './CancelAlert.css';
import arrow from '../../assets/right-arrow.svg'
import check from '../../assets/check.svg'
import loading1 from '../../assets/loadwithoutbg.gif'
import { URL_Back } from '../Variables';
import { useTranslation } from 'react-i18next';

const CancelAlert = () => {
  const {t,i18n} = useTranslation();
  const [confirmation, setConfirmation] = useState(false);
  const [checkboxValue, setCheckboxValue] = useState(0);
  const API_KEY = 'a2b18f9cfb72eb93f3ce6b1c30372b59';
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const encodedStringFromURL = window.location.search.substring(1);
  const decodedString = atob(encodedStringFromURL);
  const [email, id] = decodedString.split('_');

  const handleConfirmation = () => {
    setIsLoading(true);
    const apiUrl = `${URL_Back}/alert_request/confirm-cancel-alert`;
    const requestData = {
      email: email,
      check: checkboxValue,
      id: id
    };
    const headers = {
      'apiKey': `${API_KEY}`,
      'Content-Type': 'application/json',
      'lang':`${i18n.language}`
    };
    const requestOptions = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(requestData),
    };
    fetch(apiUrl, requestOptions)
      .then(response => {
        if (!response.ok) {
          throw new Error('Erreur lors de la requête');
        }

        return response.json();
      })
      .then(data => {

        setConfirmation(true);
      })
      .catch(error => {
        console.error('Erreur fetch :', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handleConfirmation2 = () => {
    if (checkboxValue === 1) {
      handleConfirmation()
    }
    else {
      setErrorMessage(t("cancelAlert.error"));
    }
  };

  const handleCheckboxChange = (event) => {
    setCheckboxValue(event.target.checked ? 1 : 0);
    setErrorMessage('');
  };
  if (email === '0' && id === '0') {
    return (
      <div className='container cancelAlert-container'>
        <div className='d-flex flex-column align-items-start justify-content-space-evenly'>
          <div className='container'>
            <h1> {t("cancelAlert.Unsubscribe")} </h1>
            <p>{t("cancelAlert.UnsubscribeMsg")}  <a href="mailto:contact@landes-location.com">contact@landes-location.com</a>. {t("cancelAlert.UnsubscribeMsg2")}</p>
            <button className='btn-hp mt-4' onClick={() => window.location.href = '/'}>{t("cancelAlert.hp")}  <img src={arrow} alt='arrow' /></button>
          </div>
        </div>
      </div>
    );
  } else if (id === '0' && email !== '0') {
    return (
      <div className='container cancelAlert-container'>
        <div className='d-flex flex-column align-items-start justify-content-space-evenly'>
          {confirmation ? (
            <div className='container'>
              <div className='d-flex justify-content-center flex-row gap-3 mb-4'>
                <img src={check} alt='check' />
                <h1>{t("cancelAlert.success")}</h1>
              </div>
              <p>{t("cancelAlert.gotit")} <a href="mailto:contact@landes-location.com">contact@landes-location.com</a>.</p>
              <button className='btn-hp mt-4' onClick={() => window.location.href = '/'}>{t("cancelAlert.hp")}  <img src={arrow} alt='arrow' /></button>
            </div>
          ) : (
            <div className='container'>
              <h1> {t("cancelAlert.Unsubscribe")} </h1>
              <div className='d-flex flex-column justify-content-space-evenly'>
                <p className='cancel'>
                {t("cancelAlert.already")}
                </p>
                <div className='d-flex flex-row-reverse align-items-stretch justify-content-center gap-2'>
                  <label htmlFor="unsubscribeCheckbox"> {t("cancelAlert.check")}</label>
                  <input type="checkbox" id="unsubscribeCheckbox" className='form-check-input' onChange={handleCheckboxChange} value={checkboxValue} />

                </div>
                {errorMessage && <p className="text-danger">{errorMessage}</p>}
              </div>
              <div className=' mt-4'>
                <button onClick={handleConfirmation2} className='b-confirmer me-3' disabled={isLoading} >
                  {isLoading ? (
                    <span><img src={loading1} alt="Loading" style={{ width: '30px', height: '30px' }} /></span>
                  ) : (
                    <div> {t("cancelAlert.confirm")} </div>
                  )}
                </button>
                <button className='btn-hp mt-4' onClick={() => window.location.href = '/'}>{t("cancelAlert.hp")}   <img src={arrow} alt='arrow' /></button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className='container cancelAlert-container'>

      <div className='d-flex flex-column align-items-start justify-content-space-evenly'>
        {confirmation ? (
          <div className='container'>
            <div className='d-flex justify-content-center flex-row gap-3 mb-4'>
              <img src={check} alt='check' />
              <h1>{t("cancelAlert.success")}</h1>
            </div>
            <p>{t("cancelAlert.gotit")}  <a href="mailto:contact@landes-location.com">contact@landes-location.com</a>.</p>
            <button className='btn-hp mt-4' onClick={() => window.location.href = '/'}>{t("cancelAlert.hp")}  <img src={arrow} alt='arrow' /></button>
          </div>
        ) : (
          <div className='container'>
            <h1> {t("cancelAlert.Unsubscribe")} </h1>
            <div className='d-flex flex-column justify-content-space-evenly'>
              <p className='cancel'>
                {t("cancelAlert.ready")}
                {t("cancelAlert.hope")} <a href="mailto:contact@landes-location.com">contact@landes-location.com</a> – {t("cancelAlert.UnsubscribeMsg2")}
              </p>
              <div className='d-flex flex-row-reverse align-items-stretch justify-content-center gap-2'>
                <label htmlFor="unsubscribeCheckbox">{t("cancelAlert.UnsubscribeMsg3")}</label>
                <input type="checkbox" id="unsubscribeCheckbox" className='form-check-input' onChange={handleCheckboxChange} value={checkboxValue} />

              </div>
            </div>
            <div className='d-flex justify-content-center gap-4 mt-4'>
              <button onClick={handleConfirmation} className='b-confirmer' disabled={isLoading} >
                {isLoading ? (
                  <span><img src={loading1} alt="Loading" style={{ width: '30px', height: '30px' }} /></span>
                ) : (
                  <div> {t("cancelAlert.confirm")} </div>
                )}
              </button>
              <button className='b-annuler' onClick={() => window.location.href = '/'}>Annuler</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CancelAlert;
